import * as React from 'react';

import { cn } from '@cardo/lib';
import { useEffect } from 'react';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
}

export function Modal({
  open,
  onClose,
  children,
  className,
  containerClassName,
}: ModalProps) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  return (
    <div
      className={cn(
        'fixed max-w-full max-h-full inset-0 z-40 flex justify-center items-center transition-all duration-300 ease-in-out',
        {
          hidden: !open,
        },
        containerClassName,
      )}
    >
      <div
        className={cn(
          'z-50 w-2/5 bg-white rounded-lg shadow px-5 py-6 min-w-min overflow-y-auto',
          className,
        )}
      >
        {children}
      </div>
      <button
        className="absolute h-screen w-screen bg-gray-500 opacity-20 cursor-default"
        onClick={onClose}
      ></button>
    </div>
  );
}
